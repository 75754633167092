import React from 'react';
import * as Styled from './SuccessPageContent.styled';
import { Container } from '../Container';
import tabletImg from '../../../static/4-narzedzia-do-ddd-cqrs-es-na-platformie-java-pdf-okladka.png';
import { CONTACT_EMAIL_ADDRESS } from "../../constants";
import { TextBold, TextSmall } from "./SuccessPageContent.styled";

export const SuccessPageContent = () => (
  <Styled.Wrapper>
    <Styled.SectionWrapper>
      <Styled.Header>Co dalej?</Styled.Header>
    </Styled.SectionWrapper>
    <Styled.EbookSectionContainer style={{ flex: 1 }}>
      <Container>
        <Styled.SectionWrapper>
          <Styled.TabletImage alt='tablet' src={tabletImg} />
          <Styled.EbookTextWrapper>
            <span>
              Zaloguj się do swojej skrzynki pocztowej i poszukaj maila od {CONTACT_EMAIL_ADDRESS}.
            </span>
            <TextSmall>* Upewnij się proszę, czy wiadomość nie wylądowała w folderze SPAM!</TextSmall>
            <br />
            <span>Wewnątrz znajdziesz link do swojego ebooka:</span>
            <TextBold>
              4 narzędzia do DDD/CQRS/ES na platformie Java
              <br />
              (* Plus jedno, błędnie za takie postrzegane - BONUS!)
            </TextBold>
            <br />
            <span>Dodatkowo PDF możesz otworzyć <a href="https://patternapplied.com/ebooks/4-narzedzia-do-ddd-cqrs-es-na-platformie-java.pdf" target="_blank" rel="nofollow noopener noreferrer">tutaj</a>.</span>
          </Styled.EbookTextWrapper>
        </Styled.SectionWrapper>
      </Container>
    </Styled.EbookSectionContainer>
  </Styled.Wrapper>
)
