import styled from 'styled-components';
import { getColor, getSpacing, getTypographyColor, getTypographySize, getTypographyWeight } from "../../theme/helpers";
import { MEDIA } from '../../constants';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 161px);
`;

export const EbookSectionContainer = styled.div`
  background-color: ${getColor('backgroundGray')};
  padding-top: ${getSpacing('xl')};
`;

export const Header = styled.h1`
  font-size: ${getTypographySize('lg')};
  color: ${getTypographyColor('brandBlue')};
  font-weight: ${getTypographyWeight('semiBold')};
  text-align: center;

  @media (min-width: ${MEDIA.MED}) {
    font-size: ${getTypographySize('xl')};
  }
`;

export const SectionWrapper = styled.div`
  padding-block: ${getSpacing('xl')};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  flex-wrap: wrap;
`;

export const TabletImage = styled.img`
  align-self: center;
  width: 470px;
  margin-bottom: ${getSpacing('md')};
`;

export const EbookTextWrapper = styled.div`
  color: ${getTypographyColor('brandBlue')};
  font-weight: ${getTypographyWeight('normal')};
  font-size: ${getTypographySize('xmd')};
  line-height: 27px;

  @media (min-width: ${MEDIA.MED}) {
    max-width: 50%;
  }
  
  > span {
    display: block;
  }
  
  a {
    color: ${getTypographyColor('brandBlue')};
  }
`;

export const TextSmall = styled.span`
  font-size: ${getTypographySize('small')};
`;

export const TextBold = styled.span`
  font-weight: ${getTypographyWeight('bold')};
`;