import React from 'react';
import { Helmet } from 'react-helmet';
import { string } from 'prop-types';
import {HOME_PAGE_TITLE} from '../constants';

import Layout from '../components/Layout';
import { SuccessPageContent } from "../components/SuccessPageContent/SuccessPageContent";

const SuccessPage = ({ path }) => (
  <Layout path={path}>
    <Helmet>
      <title>{HOME_PAGE_TITLE}</title>
      <meta
        name="keywords"
        content="ddd,cqrs,event sourcing,axon,kurs,domain driven design"
      />
      <meta
        name="description"
        content="Kurs DDD/CQRS/ES Applied on JVM with Axon Framework"
      />
    </Helmet>
    <SuccessPageContent />
  </Layout>
)

SuccessPage.propTypes = {
  path: string.isRequired,
}

export default SuccessPage
